html, body, #root {
	height: 100%;
	position: relative;
}

body {
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #4A495E;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
	display:inline-block;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	line-height: 18px;
	color: #145CC6;
	text-decoration: none;
	position: relative;
}

a:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	height: 1px;
	bottom: -4px;
	background-color: #145CC6;
	opacity: 0;
  transition: 0.2s opacity;
}

a:hover:after {
	opacity: 1;
}

a:first-letter {
	text-transform: capitalize;
}

:root {
	--column-size: 110px;
	--margin-between-columns: 32px;
	--header-size: 85px;
}

@media only screen and (max-width: 1920px) {
	:root {
		--column-size: 110px;
	}
}

@media only screen and (max-width: 1440px) {
	:root {
		--column-size: 84px;
	}
}

@media only screen and (max-width: 1280px) {
	:root {
		--column-size: 71px;
	}
}

@media only screen and (max-width: 1024px) {
	:root {
		--column-size: 49px;
	}
}

@media only screen and (max-width: 940px) {
	:root {
		--column-size: 41px;
		--margin-between-columns: 16px;
	}
}
/****** Fonts *******/
@font-face {
	font-family: "Moderat";
	src: url("./fonts/moderat/Moderat-Regular.eot"); /* IE9 Compat Modes */
	src: url("./fonts/moderat/Moderat-Regular.woff2") format("woff2"),
	  /* Super Modern Browsers */ url("./fonts/moderat/Moderat-Regular.woff")
		format("woff"); /* Pretty Modern Browsers */
	font-weight: 400;
	font-style: normal;
  }
  @font-face {
	font-family: "Moderat";
	src: url("./fonts/moderat/Moderat-Medium.eot"); /* IE9 Compat Modes */
	src: url("./fonts/moderat/Moderat-Medium.woff2") format("woff2"),
	  /* Super Modern Browsers */ url("./fonts/moderat/Moderat-Medium.woff")
		format("woff"); /* Pretty Modern Browsers */
	font-weight: 500;
	font-style: normal;
  }
  @font-face {
	font-family: "Moderat";
	src: url("./fonts/moderat/Moderat-Bold.eot"); /* IE9 Compat Modes */
	src: url("./fonts/moderat/Moderat-Bold.woff2") format("woff2"),
	  /* Super Modern Browsers */ url("./fonts/moderat/Moderat-Bold.woff")
		format("woff"); /* Pretty Modern Browsers */
	font-weight: 700;
	font-style: normal;
  }
  @font-face {
	font-family: "Moderat";
	src: url("./fonts/moderat/Moderat-Black.eot"); /* IE9 Compat Modes */
	src: url("./fonts/moderat/Moderat-Black.woff2") format("woff2"),
	  /* Super Modern Browsers */ url("./fonts/moderat/Moderat-Black.woff")
		format("woff"); /* Pretty Modern Browsers */
	font-weight: 900;
	font-style: normal;
  }
  @font-face {
	font-family: "Moderat Mono";
	src: url("./fonts/moderat/Moderat-Mono-Bold.eot"); /* IE9 Compat Modes */
	src: url("./fonts/moderat/Moderat-Mono-Bold.woff2") format("woff2"),
	  /* Super Modern Browsers */ url("./fonts/moderat/Moderat-Mono-Bold.woff")
		format("woff"); /* Pretty Modern Browsers */
	font-weight: 700;
	font-style: normal;
  }
  @font-face {
	font-family: "Moderat Mono";
	src: url("./fonts/moderat/Moderat-Mono-Medium.eot"); /* IE9 Compat Modes */
	src: url("./fonts/moderat/Moderat-Mono-Medium.woff2") format("woff2"),
	  /* Super Modern Browsers */ url("./fonts/moderat/Moderat-Mono-Medium.woff")
		format("woff"); /* Pretty Modern Browsers */
	font-weight: 400;
	font-style: normal;
  }